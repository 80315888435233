function Thanks (){
    return(
        <div>
            <h1>Thank you</h1>
            <h1>ありがとうございました</h1>
            
            <img src="bow.png" alt="bow" />
        </div>
    )
}

export default Thanks