function Footer() {
  return (
    <div className="footer">
      <footer class="py-2 bg-dark">
        <div class="container">
          <p class="m-0 text-center text-white">
            Copyright &copy; Kotekan Karate 2023-2024
         <br />
      <a href="https://www.youtube.com/channel/UCTK-5tkQb7RztEofIadUvyw"><i className='bx bxl-youtube bx-border-circle  bx-md' id="socials" ></i></a>
     
      <a href="https://twitter.com/Kotekan23"><i className='bx bxl-twitter bx-border-circle  bx-md' id="socials" ></i></a>
    
      <a href="https://www.instagram.com/kotekankarate2523/"><i className='bx bxl-instagram bx-border-circle  bx-md' id="socials" ></i></a>
   
          </p>
               
        </div>
      </footer>
    </div>
  );
}
export default Footer;
