import React from 'react'

function Error() {
  return (
    <div>
      <h1>404</h1>
      <h3>Sorry wrong time, wrong place</h3>
      <img src="sendit.jpeg" alt="harshbrown" />
    </div>
  )
}

export default Error